<template>
	<div id="new-customer">
		<div class="container-md">
			<user v-model="user"></user>

			<div class="flex align-start">
				<a href="#" class="btn" @click.prevent="saveUser()">Stovna</a>
			</div>
		</div>
	</div>
</template>

<script>
import User from '@/components/forms/User.vue';
import axios from 'axios';

export default {
	name: 'UserCreate',

	components: {
		User,
	},

	data() {
		return {
			user: {},
		};
	},

	methods: {
		saveUser() {
			axios
				.post('/users', this.user)
				.then(response => {
					console.log('response', response);

					this.$router.push({ name: 'Users' });
				})
				.catch(error => {
					console.log('Error creating user', error);
				});
		},
	},
};
</script>
